import React, { useState } from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const MemberCard = ({ allUsers, allErrors, teamName, memberName}) => {
  const [cur_section, setCurSection] = useState("Niagara Kings");
  

  const activeClass = (team) => {
    setCurSection(team[0].team);
  };
  return (
    <div className="row text-center">
      <div className="accordion">
        <div className="accordion-item">
          {allErrors !== ""
            ? allUsers.map((curElem, index) => {
                  return (
                    <>
                      <div
                        className={
                          "teamMain " +
                          (cur_section == curElem[0].team ? "active" : "") + (teamName || memberName !== "" ? " activeMain" : "")
                        }
                        onClick={() => activeClass(curElem)}
                      >
                        <div className="teamNameMain">
                          <div className="teamName">{curElem[0].team}</div>
                          <div className="teamIcon-plus">+</div>
                          <div className="teamIcon-minus">-</div>
                        </div>
                        <div
                          className={
                            "teamDataMain " +
                            (cur_section == curElem[0].team ? "active" : "")
                          }
                        >
                          {curElem.map((newLoop) => {
                              return (
                                <>
                                  <div className="teamDataMain-inner">
                                    <div
                                      className={`teamData ${newLoop.status}`}
                                    >
                                      <div className="teamMemberName">
                                        {newLoop.name}
                                      </div>
                                      <div className="teamMemberTitle">
                                        {newLoop.title}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  );
                })
            : "There is an error in the data source."}
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
