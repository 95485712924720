import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import SearchIcon from "./images/search-icon.png";
import "./style.css";

const MembersSearch = ({ team, teamMember }) => {
  return (
    <div className="row">
      <div className="accordion custom-search">
        <form>
          <div className="form-group row">
            <div className="col-12 col-md-6 col-sm-6">
              <div className="row">
                <div className="col-12 custom-search-inner">
                  <span className="search-icon">
                    <img src={SearchIcon}></img>
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Your Team Name"
                    onChange={(e) => team(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-6">
              <div className="row">
                <div className="col-12 custom-search-inner">
                  <span className="search-icon">
                    <img src={SearchIcon}></img>
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Your Name"
                    onChange={(e) => teamMember(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MembersSearch;
