import React from "react";
import ComputanLogo from "./images/Computan-Logo.svg";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

const Header = () => {
  return (
    <header>
      <div className="container-fluid">
        <div className="header-container">
          <div className="row">
            <div className="col-6">
              <div className="com-logo">
                <img src={ComputanLogo} />
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
