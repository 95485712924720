import React, { useState } from "react";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import MembersSearch from "./memberSearch";
import MemberCard from "./membersCards";
import Header from "./header";
import "./style.css";

const GithubUsers = ({ newUsersLatest, usersNewError }) => {
  const [queryTeam, setQueryTeam] = useState("");
  const [queryMember, setQueryMember] = useState("");

  // To get the url parameters for url search function

  const queryParams = new URLSearchParams(window.location.search);
  const teamSearch = queryParams.get("team");

  // Function to search the team in the team input box
  const handleTeam = () => {
    var searchTeamName = newUsersLatest.filter((filterTeam) =>
      filterTeam[0].team.toLowerCase().includes(queryTeam.toLowerCase())
    );
    return searchTeamName;
  };

  // Function to search the data according to the url parameters right now set for only teams
  const handleTeamUrlSearch = () => {
    var searchTeamNameUrl = newUsersLatest.filter((filterTeamUrl) =>
      filterTeamUrl[0].team.toLowerCase().includes(teamSearch.toLowerCase())
    );
    return searchTeamNameUrl;
  };

  // Function to search the team member in the team input box
  const handleTeamMember = () => {
    var searchMemberName = [];
    newUsersLatest.map((filterMember) => {
      const keys = ["name","title","status"]
      var finalFilterMembers = filterMember.filter(
        (filterByData) =>
          keys.some(key=>filterByData[key].toLowerCase().includes(queryMember.toLowerCase()))
      );
      searchMemberName.push(finalFilterMembers);
    });
    var finalMemberNames = searchMemberName.filter((col) => col.length !== 0);
    return finalMemberNames;
  };

  var updatedUsers = newUsersLatest;

  // Conditions to set/filter the users according to the URL, Team Input Box, and Member input box
  if (queryMember !== "") {
    updatedUsers = handleTeamMember();
  } else if (queryTeam !== "") {
    updatedUsers = handleTeam();
  } else if(teamSearch !== "" && teamSearch !== null){
    updatedUsers = handleTeamUrlSearch()
  }

  return (
    <div>
      <Header />
      <div className="container-fluid mt-5">
        <MembersSearch team={setQueryTeam} teamMember={setQueryMember} />
        <MemberCard
          allUsers={updatedUsers}
          allErrors={usersNewError}
          teamName={queryTeam}
          memberName={queryMember}
        />
      </div>
    </div>
  );
};

export default GithubUsers;
